import { IOdataListColumnDefinition } from 'features/OdataList/common/types'

export type FeeDashboardListColumnName =
  | 'Bill Date'
  | 'Account Number'
  | 'Legal Entity Name'
  | 'Debit Account Number'
  | 'Rep Code'
  | 'Env Bill Period Start'
  | 'Env Bill Period End'
  | 'Platform Fee Period Start'
  | 'Platform Fee Period End'
  | 'COB Managed Assets'
  | 'Model Type'
  | 'Pending Rockefeller Fee'
  | 'Pending Manager Fee'
  | 'Pending Client Fee'
  | 'Pending Platform Fee'
  | 'Fees From Other Accounts'
  | 'Total Pending Debit'
  | 'Cash Available To Withdraw'
  | 'Remaining Cash After Total Pending Debit'
  | 'Managed Model Type'
  | 'Division'
  | 'Team'
  | 'Household'
  | 'Rep Name'

export interface IFeeDashboardListColumnDefinition
  extends IOdataListColumnDefinition {
  name: FeeDashboardListColumnName
}

const defaultColumn: Partial<IFeeDashboardListColumnDefinition> = {
  filterable: true,
  sortable: true
}

export const feeDashboardListColumns: IFeeDashboardListColumnDefinition[] = [
  {
    ...defaultColumn,
    name: 'Account Number',
    dataPath: 'accountNumber',
    type: 'string',
    searchFields: ['accountNumber'],
    width: 100
  },
  {
    ...defaultColumn,
    name: 'Legal Entity Name',
    dataPath: 'account/party/partyName',
    type: 'string',
    searchFields: ['account/party/partyName'],
    expand: ['account($expand=party($select=partyName))'],
    width: 220
  },
  {
    ...defaultColumn,
    name: 'Bill Date',
    dataPath: 'billDate',
    type: 'date-only',
    width: 90
  },
  {
    ...defaultColumn,
    name: 'COB Managed Assets',
    dataPath: 'account/accountMetrics/aumManaged',
    expand: ['account($expand=accountMetrics($select=aumManaged))'],
    type: 'number',
    width: 150
  },
  {
    ...defaultColumn,
    name: 'Managed Model Type',
    dataPath: 'modelType',
    type: 'string',
    facetable: true,
    width: 160
  },
  {
    ...defaultColumn,
    name: 'Pending Manager Fee',
    dataPath: 'pendingManagerFee',
    type: 'number',
    width: 100
  },
  {
    ...defaultColumn,
    name: 'Pending Rockefeller Fee',
    dataPath: 'pendingRockFee',
    type: 'number',
    width: 100
  },
  {
    ...defaultColumn,
    name: 'Pending Client Fee',
    dataPath: 'pendingClientFee',
    type: 'number',
    width: 120
  },
  {
    ...defaultColumn,
    name: 'Pending Platform Fee',
    select: ['pendingPlatformFee', 'accountNumber', 'billDate'],
    dataPath: 'pendingPlatformFee',
    type: 'number',
    width: 100
  },
  {
    ...defaultColumn,
    name: 'Fees From Other Accounts',
    select: ['feeFromOtherAccounts', 'accountNumber', 'billDate'],
    dataPath: 'feeFromOtherAccounts',
    type: 'number',
    width: 120
  },
  {
    ...defaultColumn,
    name: 'Total Pending Debit',
    dataPath: 'totalPendingDebit',
    type: 'number',
    width: 150
  },
  {
    ...defaultColumn,
    name: 'Cash Available To Withdraw',
    dataPath: 'cashAvailable',
    type: 'number',
    width: 150
  },
  {
    ...defaultColumn,
    name: 'Remaining Cash After Total Pending Debit',
    dataPath: 'remainingCash',
    type: 'number',
    width: 150
  },
  {
    ...defaultColumn,
    name: 'Rep Code',
    dataPath: 'account/registeredRep',
    type: 'string',
    expand: ['account($select=registeredRep)'],
    width: 60
  },
  {
    ...defaultColumn,
    name: 'Rep Name',
    dataPath: 'account/advisor/name',
    type: 'string',
    facetable: true,
    expand: ['account($expand=advisor($select=name))'],
    width: 120
  },
  {
    ...defaultColumn,
    name: 'Debit Account Number',
    dataPath: 'debitAccountNumber',
    type: 'string',
    searchFields: ['debitAccountNumber'],
    width: 100
  },
  {
    ...defaultColumn,
    name: 'Env Bill Period Start',
    dataPath: 'envBillPeriodStart',
    type: 'date-only',
    width: 90
  },
  {
    ...defaultColumn,
    name: 'Env Bill Period End',
    dataPath: 'envBillPeriodEnd',
    type: 'date-only',
    width: 90
  },
  {
    ...defaultColumn,
    name: 'Platform Fee Period End',
    dataPath: 'platformFeePeriodStart',
    type: 'date-only',
    width: 90
  },
  {
    ...defaultColumn,
    name: 'Platform Fee Period Start',
    dataPath: 'platformFeePeriodEnd',
    type: 'date-only',
    width: 90
  },
  {
    ...defaultColumn,
    name: 'Division',
    dataPath: 'account/advisor/regionName',
    type: 'string',
    facetable: true,
    expand: ['account($expand=advisor($select=regionName))'],
    width: 140
  },
  {
    ...defaultColumn,
    name: 'Team',
    dataPath: 'account/advisor/teamName',
    type: 'string',
    facetable: true,
    expand: ['account($expand=advisor($select=teamName))'],
    width: 160
  },
  {
    ...defaultColumn,
    name: 'Household',
    dataPath: 'account/Household/HouseholdName',
    type: 'string',
    facetable: true,
    searchFields: ['account/Household/HouseholdName'],
    expand: ['account($expand=Household($select=householdName))'],
    width: 200
  }
]
