import { skipToken } from '@reduxjs/toolkit/query'
import { DateOnlyCell } from 'features/Lists/core/components/DateCell'
import { USDCell } from 'features/Lists/core/components/USDCell'
import { useMemo } from 'react'
import { IFeeDetailRequest, useGetPlatformFeeHistoryQuery } from '../../../api'

export const PlatformFeeDetail: React.FC<Partial<IFeeDetailRequest>> = ({
  accountNumber,
  billDate
}) => {
  const { data } = useGetPlatformFeeHistoryQuery(
    accountNumber && billDate ? { accountNumber, billDate } : skipToken
  )

  const fee = useMemo(() => data?.[0], [data])

  return (
    <table
      css={{
        padding: '10px',
        '& th, & td': { padding: '1px 5px' },
        '& th': { textAlign: 'right' }
      }}
    >
      <tr>
        <th>Account Number</th>
        <td>{fee?.accountNumber}</td>
      </tr>
      <tr>
        <th>Bill Period Start Date</th>
        <td>
          <DateOnlyCell value={fee?.billStartDate} />
        </td>
      </tr>
      <tr>
        <th>Bill Period End Date</th>
        <td>
          <DateOnlyCell value={fee?.billEndDate} />
        </td>
      </tr>
      <tr>
        <th>Account AUM</th>
        <td>
          <USDCell value={fee?.unadjustedAum} />
        </td>
      </tr>
      <tr>
        <th>Adjusted AUM</th>
        <td>
          <USDCell value={fee?.adjustedAum} />
        </td>
      </tr>
      <tr>
        <th>Gross Fee</th>
        <td>
          <USDCell value={fee?.grossFee} /> ({fee?.grossFeePct}%)
        </td>
      </tr>
      <tr>
        <th>Total Credit</th>
        <td>
          <USDCell value={fee?.creditFee} /> ({fee?.creditFeePct}%)
        </td>
      </tr>
      <tr>
        <th>Net Platform Fee</th>
        <td>
          <USDCell value={fee?.netFee} /> ({fee?.netFeePct}%)
        </td>
      </tr>
    </table>
  )
}
