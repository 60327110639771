import axios from 'axios'
import { IDragDropFile } from '../../../../../shared/components/DragDrop'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import {
  IAllCashReceipt,
  IAllocatedAmount,
  ICheckImageDocument,
  IDownloadFileRequest,
  IProductType
} from '../store/types'

const getAllCashReceiptsService = (monthyear: string, options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/Retirement/GetAllCashreceipts?monthyear=${monthyear}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IAllCashReceipt[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const getAllProductTypesService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/Retirement/GetAllProductTypes`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IProductType[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const deleteDocumentService = (options: IApiOptions, deletedocid: string) => {
  const url = `${options.apiRoot}/cdm/Retirement/DeleteCashReceiptDocument?cashreceiptdocid=${deletedocid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<boolean | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const updateAllCashReceiptService = (
  receipts: IAllCashReceipt[],
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Retirement/UpdateCashReceipts`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  const data = receipts.map((x) => ({
    cashreceiptid: x.cashreceiptid,
    producttypeid: x.producttypeid,
    planproviderid: x.planproviderid,
    offsetaccount: x.offsetaccount,
    selectedproducttypeid: x.selectedproducttypeid,
    totalsplitamount: x.totalsplitamount
  }))
  return axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const updateCashReceiptByProviderService = (
  receipt: IAllCashReceipt,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Retirement/InsertUpdateCashReceiptsByProvider`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .post(url, receipt, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const uploadCheckImageDocumentService = (
  doc: ICheckImageDocument,
  options: IApiOptions,
  onUploadProgress?: (
    progressEvent: ProgressEvent<EventTarget>,
    file: IDragDropFile
  ) => void
) => {
  const url = `${options.apiRoot}/cdm/Retirement/UploadCashReceipt`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  const data = new FormData()
  data.append('filecontents', doc.file.file)
  data.append('cashreceiptid', doc.cashReceiptId?.toString())
  data.append('documenttype', doc.documenttype)
  data.append('cashreceiptproviderid', doc.cashreceiptproviderid || '')
  return axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken,
      onUploadProgress: onUploadProgress
        ? (progressEvent) => {
            onUploadProgress(progressEvent, doc.file)
          }
        : undefined
    })
    .then((res) => res.data)
}

const viewCashReceiptCheckService = (
  viewCahReceiptReq: IDownloadFileRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Retirement/DownloadCashReceipt?cashreceiptid=${viewCahReceiptReq.cashreceiptid}&documenttype=${viewCahReceiptReq.documenttype}&producttype=${viewCahReceiptReq.producttype}&cashreceiptproviderid=${viewCahReceiptReq.cashreceiptproviderid}&cashreceiptdocumentid=${viewCahReceiptReq.cashreceiptdocumentid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<string>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const getAllocatedAmount = (
  cashReceipt: IAllCashReceipt,
  monthYear: string | undefined,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Retirement/GetCashReceiptAllocations?monthyear=${monthYear}&cashreceiptid=${cashReceipt.cashreceiptid} `
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IAllocatedAmount[] | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export {
  getAllCashReceiptsService,
  getAllProductTypesService,
  updateAllCashReceiptService,
  uploadCheckImageDocumentService,
  viewCashReceiptCheckService,
  deleteDocumentService,
  updateCashReceiptByProviderService,
  getAllocatedAmount
}
