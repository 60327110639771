import axios from 'axios'
import { IApiOptions } from '../../../../shared/contracts/IApiOptions'
import { RootObject } from '../store/approvalDashboardComparisonTypes'
import {
  IApiResponse,
  IApproverAction,
  IDraftPlanByProvider
} from '../store/types'

const fetchDraftPlansForApprovalDashboard = (
  options: IApiOptions,
  providerId?: number,
  planId?: number,
  draftId?: number
) => {
  const url = `${options.apiRoot}/cdm/Retirement/GetDraftPlansByProvider?providerid=${providerId}&producttype=alts&draftid=${draftId}`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IDraftPlanByProvider>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data.result?.[0])
}

const fetchComparisionDataApprovalDashboardService = (
  options: IApiOptions,
  providerId?: number,
  planId?: number,
  draftId?: number
) => {
  const url = `${options.apiRoot}/cdm/WorkflowPlan/GetDraftCommittedByPlan?providerid=${providerId}&producttype=alts&draftid=${draftId}&planid=${planId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<RootObject>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const handleApproverActionApprovalDashboardService = (
  options: IApiOptions,
  payload: IApproverAction
) => {
  const url = `${options.apiRoot}/cdm/WorkflowPlan/UpdatePlanStatus`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IApiResponse>(url, payload, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export {
  fetchDraftPlansForApprovalDashboard,
  fetchComparisionDataApprovalDashboardService,
  handleApproverActionApprovalDashboardService
}
