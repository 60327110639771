/* eslint-disable import/namespace */
import axios from 'axios'
import { push } from 'connected-react-router'
import { format, parse } from 'date-fns'
import { get } from 'lodash'
import { getLocation } from 'shared/services/dom'
import {
  call,
  cancelled,
  put,
  select,
  takeEvery,
  takeLatest
  // tslint:disable-next-line: no-submodule-imports
} from 'typed-redux-saga'
import { IApiOptions } from '../../../shared/contracts/IApiOptions'
import { IEnvironmentApiConfiguration } from '../../../shared/services/environment/IEnvironmentConfiguration'
import { exportDataToExcel } from '../../../shared/xlsx'
import {
  getRockefellerApiOptions,
  tryAcquireAccessToken
} from '../../../store/shared/sagas'
import { getRockefellerApiConfig } from '../../../store/system'
import { getRdotUsername } from '../../../store/user/selectors'
import {
  addScheduleFeeService,
  deleteScheduleFeeService,
  fetchFeeFutureService,
  fetchFeePastChargesService,
  fetchFeeSchedulesService,
  fetchFeeService,
  fetchFeeTypesService,
  chargeFeeService,
  closeMonthService,
  deleteFeeInvoiceService,
  downloadD365ExcelService,
  fetchAccrualsService,
  fetchDashboardFeesService,
  fetchFeeCategoriesService,
  fetchInvoiceDetailsItemsService,
  fetchInvoicesService,
  generateInvoiceService,
  getPilotFeature,
  sendForCollectionService,
  updateAccrualsService,
  updateAnnualAmountService,
  viewInvoiceService,
  fetchFeeGridSchedulesService,
  fetchFeeScheduleService,
  insertUpdateFeeOverrideService,
  fetchAssetClassSecurityService,
  fetchExistingOverridebyIdService,
  deleteFeeScheduleFeeService,
  activateFeeScheduleService,
  fetchFeeOverridesListService,
  fetchGroupByAccountIdService,
  insertUpdateTiredAumFeesService,
  fetchFeeAssignmentService,
  fetchFeeGroupListService,
  insertUpdateFeeGroupService,
  insertUpdateFeeBuildGroupService,
  fetchExistingFeeBuildGroupService,
  deleteFeeAssignmentService,
  fetchExistingTiredAumFeesService,
  fetchAccountService,
  fetchFlatFeeScheduleService,
  updateFlatFeeScheduleService,
  fetchTrustCategoryAssignmentService,
  handleTrustCategoryActionService,
  fetchAccountsForScheduleService,
  getGwesReportService,
  fetchDashboardFeesBillingService,
  runBillingService,
  fetchPlatformFeeScheduleService,
  updatePlatformFeeScheduleService,
  fetchPlatformFeeCalcPeriodService,
  fetchPlatformFeeDetailService
} from '../api/feesService'
import {
  createFeeScheduleService,
  createFeeService,
  fetchAgencyFeeService,
  fetchDepositAccountService,
  fetchFeeBusinessSegmentsService,
  fetchFeeCategoryTypesService,
  fetchFeeCollectionTypesService,
  fetchFeeFrequenciesService,
  fetchFeeGLString,
  fetchFeeRepCodesService,
  fetchFeeServiceTypesService,
  fetchGLAccountService,
  fetchInvoiceFeeTypesService,
  updateFeeScheduleService
} from '../api/scheduleFeeFormService'
import {
  IFeeCategory,
  IFeeCharge,
  IFeeFilter,
  IFeeType,
  IFutureFeeCharge,
  IFeeScheduleFilter,
  IDeleteResponse
} from '../api/types/feefilter.type'
import { IAssetLookupByRowResponse, IGroupRow } from '../api/types/types'
import {
  fetchAccountActions,
  feeDataActions,
  feeFormActions,
  FeeFormActionType,
  feeFutureChargesActions,
  feeListActions,
  FeeListActionType,
  feePastChargesActions,
  feeTypeActions,
  chargeFeeActions,
  exportScheduledFeeActions,
  feeCategoryTypeActions,
  feeNavigationActions,
  setSelectedFeePlan,
  feeDashboardActions,
  fetchAccrualsActions,
  exportFeePlansToExcelActions,
  pilotFetaureActions,
  UpdateAccrualsActions,
  viewInvoiceActions,
  generateInvoiceActions,
  closeMonthActions,
  getInvoicesActions,
  downloadD365ExcelActions,
  sendForCollectionActions,
  updateAmountActions,
  fetchInvoiceDetailItemsActions,
  feeGLStringActions,
  feeScheduleListActions,
  FeeScheduleListActionType,
  feeScheduleDataActions,
  insertUpdateFeeOverrideActions,
  fetchAssetSecurityDataActions,
  fetchAssetSecurityDataPerRowActions,
  fetchSecuritiesActions,
  fetchExistingOverrideByIdActions,
  insertUpdateFeeScheduleActions,
  fetchFeeOverridesDataActions,
  deleteFeeOverrideActions,
  fetchGroupByAccountIdActions,
  insertUpdateTiredAumFeesActions,
  fetchFeeAssignmentDataActions,
  fetchFeeGroupDataActions,
  insertUpdateFeeBuildGroupActions,
  fetchExistingBuildGroupActions,
  deleteFeeGroupActions,
  deleteFeeAssignmentActions,
  fetchExistingTiredAumFeesActions,
  getFlatFeeScheduleAction,
  updateFlatFeeScheduleAction,
  fetchTrustAssignmentDataActions,
  handleTrustActions,
  getAccountsForScheduleActions,
  exportToExcelGwesReportActions,
  feeBillingDashboardActions,
  exportFeeBillingToExcelActions,
  runBillingActions,
  platformFeeScheduleActions,
  updatePlatformFeeScheduleActions,
  exportplatformFeeScheduleToExcelActions,
  platformFeeDetailActions,
  platformFeeCalcPeriodActions,
  exportPlatformNetFlowsActions
} from './actions'
import {
  createNewFeeActions,
  fetchAgencyFeeTypeActions,
  fetchGLAccountActions,
  fetchDepositAccountActions,
  fetchFeeAdvisorActions,
  fetchFeeBusinessSegmentTypeActions,
  fetchFeeCategoriesActions,
  fetchFeeCollectionTypeActions,
  fetchFeeFrequencyTypeActions,
  fetchFeeServiceTypeActions,
  fetchInvoiceFeeTypeActions,
  updateNewFeeActions
} from './scheduleFeeFormActions'
import {
  getAssetSecurityDataByRowState,
  getFeeBillingSelectedMonthYear,
  getFeeGridListData,
  getFeeListData,
  getFetchGroupByAccountIdState,
  getInvoicesState,
  getSelectedFeeId,
  getSelectedFeePlan,
  getSelectedFeeSchedule,
  getSelectedMonthYear
} from './selectors'
import {
  IFlagFeeSchedule,
  IScheduleInfo,
  IFeeDetails,
  IFeePlan,
  IPilotFeatureRequest,
  IPilotFeaturesList,
  IInvoicesRequest,
  IFeeInvoices,
  NewFeeRequest,
  UpdateFeeRequest,
  IFeeScheduleForm,
  IFeeListDetail
} from './types'

import {
  formatDeleteFeeGroupRequest,
  formatDeleteFeeRequest,
  formatInsertUpdateServiceRequest,
  getMonthYearStringFromSelectedMonth,
  getTotalAccruals
} from './utility'

function* handleFetchFeeSchedulesInfo(action: FeeListActionType) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IFeeDetails[] = yield call(() =>
      fetchFeeSchedulesService(action.payload as IFeeFilter, options)
    )

    yield put(feeListActions.success(data))
  } catch (e: any) {
    yield put(feeListActions.failure(e))
  }
}
function* handleFetchFeeGridSchedulesInfo(action: FeeScheduleListActionType) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IFeeListDetail[] = yield call(() =>
      fetchFeeGridSchedulesService(
        action.payload as IFeeScheduleFilter,
        options
      )
    )

    yield put(feeScheduleListActions.success(data))
  } catch (e: any) {
    yield put(feeScheduleListActions.failure(e))
  }
}

function* handleFetchFeeTypes() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IFeeType[] = yield call(() => fetchFeeTypesService(options))

    yield put(feeTypeActions.success(data))
  } catch (e: any) {
    yield put(feeTypeActions.failure(e))
  }
}

function* handleFetchFeeData() {
  try {
    const feeid: string = yield select(getSelectedFeeId)
    if (feeid) {
      const options = yield* call(getRockefellerApiOptions)
      const data: IFeeDetails = yield call(() =>
        fetchFeeService(feeid, options)
      )

      yield put(feeDataActions.success(data))
    } else {
      throw new Error('Fee Id is present')
    }
  } catch (e: any) {
    yield put(feeTypeActions.failure(e))
  }
}

function* navigateToFeeView(action: FeeListActionType) {
  yield put(feeListActions.setfeeid(action.payload as string))
  const { pathname } = yield* call(getLocation)
  const path = `${pathname}/view/`
  yield put(push({ pathname: path }))
}

function* navigateToFeeEdit(action: FeeListActionType) {
  yield put(feeListActions.setfeeid(action.payload as string))
  const { pathname } = yield* call(getLocation)
  const path = `${pathname}/schedule/`
  yield put(push({ pathname: path }))
}
function* navigateToFeeGridView(action: FeeScheduleListActionType) {
  yield put(
    feeScheduleListActions.setfeeschedule(action.payload as IFeeListDetail)
  )
  const { pathname } = yield* call(getLocation)
  if (pathname === '/fees/feegrid') {
    const path = `${pathname}/view/`
    yield put(push({ pathname: path }))
  }
}

function* navigateToFeeGridEdit(action: FeeScheduleListActionType) {
  yield put(
    feeScheduleListActions.setfeeschedule(action.payload as IFeeListDetail)
  )
  const { pathname } = yield* call(getLocation)
  const path = `${pathname}/schedule/`
  yield put(push({ pathname: path }))
}

function* handleFetchPastCharges() {
  try {
    const feeid: string = yield select(getSelectedFeeId)
    if (feeid) {
      const options = yield* call(getRockefellerApiOptions)
      const data: IFeeCharge[] = yield call(() =>
        fetchFeePastChargesService(feeid, options)
      )
      yield put(feePastChargesActions.success(data))
    } else {
      throw new Error('Fee Id is not present')
    }
  } catch (e: any) {
    yield put(feePastChargesActions.failure(e))
  }
}

function* handleFetchFutureCharges() {
  try {
    const feeid: string = yield select(getSelectedFeeId)
    if (feeid) {
      const options = yield* call(getRockefellerApiOptions)
      const data: IFutureFeeCharge[] = yield call(() =>
        fetchFeeFutureService(feeid, options)
      )
      yield put(feeFutureChargesActions.success(data))
    } else {
      throw new Error('Fee Id is not present')
    }
  } catch (e: any) {
    yield put(feeFutureChargesActions.failure(e))
  }
}

function* handleDeleteFee(action: FeeListActionType) {
  try {
    const feeid: string = action.payload as string
    if (feeid) {
      const options = yield* call(getRockefellerApiOptions)
      yield call(() => deleteScheduleFeeService(feeid, options))
      const feelist: IScheduleInfo[] = yield select(getFeeListData)
      const newlist = feelist.filter((fee) => {
        return fee.id !== feeid
      })
      yield put(feeListActions.success(newlist))
    } else {
      throw new Error('Fee Id is not present')
    }
  } catch (e: any) {
    yield put(feeFutureChargesActions.failure(e))
  }
}
function* handleDeleteFeeSchedule(action: FeeScheduleListActionType) {
  try {
    const feeid: string = action.payload as string
    if (feeid) {
      const options = yield* call(getRockefellerApiOptions)
      const result: IDeleteResponse = yield call(() =>
        deleteFeeScheduleFeeService(feeid, options)
      )
      if (result.status === 'failure') {
        yield put(feeScheduleListActions.deleteFailure(result.errormessage))
      } else {
        const feelist: IFeeListDetail[] = yield select(getFeeGridListData)
        const newlist = feelist.filter((fee) => {
          return fee.feestructureid !== Number(feeid)
        })
        yield put(feeScheduleListActions.success(newlist))
      }
    } else {
      throw new Error('Fee Id is not present')
    }
  } catch (e: any) {
    yield put(feeScheduleListActions.failure(e))
  }
}

function* handleActiveInActiveFeeSchedule(action: FeeScheduleListActionType) {
  try {
    const feeid: IFlagFeeSchedule = action.payload as IFlagFeeSchedule
    if (feeid) {
      const options = yield* call(getRockefellerApiOptions)
      let result: any
      yield call(async () => {
        result = await activateFeeScheduleService(feeid, options)
      })
      if (result.search('Flaged the FeeSchedule') > -1) {
        yield put(feeListActions.request(undefined))
      } else {
        yield put(feeScheduleListActions.deleteFailure(result))
      }
    } else {
      throw new Error('Fee Id is not present')
    }
  } catch (e: any) {
    yield put(feeScheduleListActions.failure(e))
  }
}

function* handleNaviagteToFee() {
  yield put(feeDataActions.reset())
  yield put(push('/fees/feeschedule'))
}

function* handleAddNewFee(action: FeeFormActionType) {
  try {
    const payload = action.payload as IScheduleInfo
    const options = yield* call(getRockefellerApiOptions)
    yield call(() => addScheduleFeeService(payload, options))
    yield put(push('/fees/feeschedule'))
  } catch (e: any) {
    yield put(feeFormActions.error(e))
  }
}

function* handleChargeFee(action: ReturnType<typeof chargeFeeActions.request>) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    yield call(() => chargeFeeService(action.payload as Date, options))
    yield put(chargeFeeActions.success())
  } catch (e: any) {
    yield put(chargeFeeActions.failure(e))
  }
}

function* handleExportScheduledFee(
  action: ReturnType<typeof exportScheduledFeeActions.request>
) {
  try {
    const filename = 'Scheduled_Fees.xlsx'
    const wsName = 'Fees'

    const { fees, columns } = action.payload

    const data = fees?.map((fee) => {
      return columns?.map((column): unknown =>
        column.fieldName ? get(fee, column.fieldName) : ''
      )
    })

    data?.unshift(columns.map((x) => x.name))

    yield call(() =>
      exportDataToExcel(
        {
          sheets: [{ name: wsName, data: data || [] }]
        },
        filename
      )
    )
    yield put(exportScheduledFeeActions.success())
  } catch (e: any) {
    yield put(exportScheduledFeeActions.failure(e))
  }
}

function* handleFetchFeeCategories() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IFeeCategory[] = yield call(() =>
      fetchFeeCategoriesService(options)
    )

    yield put(feeCategoryTypeActions.success(data))
  } catch (e: any) {
    yield put(feeCategoryTypeActions.failure(e))
  }
}
function* handleNavigateToAccruedAmount(
  action: ReturnType<typeof feeNavigationActions.navigateToAccruedAmount>
) {
  const { feePlan } = action.payload
  yield put(setSelectedFeePlan.selectedFeePlan(feePlan))
  let { pathname } = yield* call(getLocation)
  const indexofSlash = pathname.lastIndexOf(`/`)
  if (indexofSlash === pathname.length - 1) {
    pathname = pathname.slice(0, -1)
  }
  const path = `${pathname}/accruedamount/${feePlan?.planid}&&partyUniqueId=${feePlan?.PartyUniqueId}`
  yield put(push({ pathname: path }))
}
function* handleNavigateToFeeDashboard() {
  const path = `/fees`
  yield put(push({ pathname: path }))
}
function* handleFetchFeeDashboard() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const monthYear: string = yield select(getSelectedMonthYear)
    const data = yield* call(() =>
      fetchDashboardFeesService(monthYear, options)
    )
    const returndata = getTotalAccruals(data || [])
    yield put(feeDashboardActions.success(returndata || []))
  } catch (e: any) {
    yield put(feeDashboardActions.failure(e))
  }
}
function* handleFetchPlatformFeeSchedule() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() => fetchPlatformFeeScheduleService(options))
    yield put(platformFeeScheduleActions.success(data))
  } catch (e: any) {
    yield put(platformFeeScheduleActions.failure(e))
  }
}

function* handleUpdatePlatformFeeSchedule(
  action: ReturnType<typeof updatePlatformFeeScheduleActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    yield* call(() => updatePlatformFeeScheduleService(action.payload, options))
    yield put(
      updatePlatformFeeScheduleActions.success(
        `Platform Fee Schedule has been updated successfully`
      )
    )
  } catch (e: any) {
    yield put(updatePlatformFeeScheduleActions.failure(e))
  }
}
function* handleExportPlatformFeeScheduleToExcel(
  action: ReturnType<typeof exportplatformFeeScheduleToExcelActions.request>
) {
  try {
    const { platformFeeSchedule, columns } = action.payload

    const filename = 'Platform_Fee_Schedule.xlsx'
    const wsName = 'Platform_Fee_Schedule'

    const data = platformFeeSchedule?.map((platformFeeSchedule) => {
      const excelPlan = {
        ...platformFeeSchedule
      }
      return columns?.map((column): unknown =>
        column.fieldName ? get(excelPlan, column.fieldName) : ''
      )
    })

    data?.unshift(
      columns?.filter((x) => x.key !== 'details')?.map((x) => x.name)
    )

    yield call(() =>
      exportDataToExcel(
        {
          sheets: [{ name: wsName, data: data || [] }]
        },
        filename
      )
    )
    yield put(exportplatformFeeScheduleToExcelActions.success())
  } catch (e: any) {
    yield put(exportplatformFeeScheduleToExcelActions.failure(e))
  }
}

function* handleFetchPlatformFeeDetail(
  action: ReturnType<typeof platformFeeDetailActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchPlatformFeeDetailService(options, action.payload)
    )
    if (data?.success) {
      yield put(platformFeeDetailActions.success(data?.data[0]))
    } else {
      yield put(
        platformFeeDetailActions.failure(new Error(data?.responseMessage))
      )
    }
  } catch (e: any) {
    yield put(
      platformFeeDetailActions.failure(
        new Error(e?.response?.data?.responseMessage)
      )
    )
  }
}

function* handleFetchPlatformCalcPeriodDetail() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() => fetchPlatformFeeCalcPeriodService(options))
    if (data?.success) {
      yield put(platformFeeCalcPeriodActions.success(data?.data))
    }
  } catch (e: any) {
    yield put(platformFeeCalcPeriodActions.failure(e))
  }
}
function* handleFetchFeeBillingDashboard() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const monthYear: string = yield select(getFeeBillingSelectedMonthYear)
    const data = yield* call(() =>
      fetchDashboardFeesBillingService(monthYear, options)
    )
    yield put(feeBillingDashboardActions.success(data))
  } catch (e: any) {
    yield put(feeBillingDashboardActions.failure(e))
  }
}
function* handleRunBilling() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const monthYear: string = yield select(getFeeBillingSelectedMonthYear)
    yield* call(() => runBillingService(monthYear, options))
    yield put(
      runBillingActions.success(
        `Billing for Month ${getMonthYearStringFromSelectedMonth(
          monthYear
        )} has been run successfully`
      )
    )
  } catch (e: any) {
    yield put(runBillingActions.failure(e))
  }
}
function* handleFetchAccruals() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const monthYear: string = yield select(getSelectedMonthYear)
    const selectedFeePlan: IFeePlan = yield select(getSelectedFeePlan)

    if (!selectedFeePlan) {
      yield put(push({ pathname: '/fees/dashboard' }))
    }
    const data = yield* call(() =>
      fetchAccrualsService(monthYear, selectedFeePlan, options)
    )
    yield put(fetchAccrualsActions.success(data || []))
  } catch (e: any) {
    yield put(fetchAccrualsActions.failure(e))
  }
}
function* handleExportPlansToExcel(
  action: ReturnType<typeof exportFeePlansToExcelActions.request>
) {
  try {
    const { plans, columns } = action.payload

    const filename = 'Fee_Plans.xlsx'
    const wsName = 'Fees'

    const data = plans?.map((plan) => {
      const excelPlan = {
        ...plan,
        TotalPeriodImFee: plan?.Accruals?.[0]?.TotalPeriodImFee || 0,
        TotalPeriodAdminFee: plan?.Accruals?.[0]?.TotalPeriodAdminFee || 0,
        TotalAccrualImFee: plan?.Accruals?.[0]?.TotalAccrualImFee || 0,
        TotalAccrualAdminFee: plan?.Accruals?.[0]?.TotalAccrualAdminFee || 0
      }
      return columns?.map((column): unknown =>
        column.fieldName ? get(excelPlan, column.fieldName) : ''
      )
    })

    data?.unshift(
      columns?.filter((x) => x.key !== 'details')?.map((x) => x.name)
    )

    yield call(() =>
      exportDataToExcel(
        {
          sheets: [{ name: wsName, data: data || [] }]
        },
        filename
      )
    )
    yield put(exportFeePlansToExcelActions.success())
  } catch (e: any) {
    yield put(exportFeePlansToExcelActions.failure(e))
  }
}

function* handleExportFeeBillingToExcel(
  action: ReturnType<typeof exportFeeBillingToExcelActions.request>
) {
  try {
    const { feeBilling, columns } = action.payload

    const filename = 'Fee_Billing.xlsx'
    const wsName = 'FeeBilling'

    const data = feeBilling?.map((feebilling) => {
      const excelPlan = {
        ...feebilling
      }
      return columns?.map((column): unknown =>
        column.fieldName ? get(excelPlan, column.fieldName) : ''
      )
    })

    data?.unshift(
      columns?.filter((x) => x.key !== 'details')?.map((x) => x.name)
    )

    yield call(() =>
      exportDataToExcel(
        {
          sheets: [{ name: wsName, data: data || [] }]
        },
        filename
      )
    )
    yield put(exportFeeBillingToExcelActions.success())
  } catch (e: any) {
    yield put(exportFeeBillingToExcelActions.failure(e))
  }
}

function* handlePilotFeatures() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const email = yield* select(getRdotUsername)

    if (!email) {
      throw new Error('Invalid State: email is undefined')
    }

    const req: IPilotFeatureRequest = {
      loginId: email,
      applicationName: 'PAYMENTHUB'
    }

    const data: IPilotFeaturesList = yield call(() =>
      getPilotFeature(req, options)
    )
    yield put(pilotFetaureActions.success(data))
  } catch (e: any) {
    yield put(pilotFetaureActions.failure(e))
  }
}
function* handleUpdateAccruals(
  action: ReturnType<typeof UpdateAccrualsActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)

    yield* call(() => updateAccrualsService(action.payload, options))
    yield put(UpdateAccrualsActions.success(true))
  } catch (e: any) {
    yield put(UpdateAccrualsActions.failure(e))
  }
}

function* handleViewInvoice(
  action: ReturnType<typeof viewInvoiceActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const data = yield* call(() => viewInvoiceService(action.payload, options))

    yield put(viewInvoiceActions.success())
    const pdfWindow = window.open('', 'statementWindow')
    pdfWindow?.document.write(
      `<iframe width='100%' height='100%' src=
        ${encodeURI(data)}
        ></iframe>`
    )
  } catch (e: any) {
    yield put(viewInvoiceActions.failure(e))
  }
}
function* handleGenerateInvoice(
  action: ReturnType<typeof generateInvoiceActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const data = yield* call(() =>
      generateInvoiceService(action.payload, options)
    )

    yield put(generateInvoiceActions.success(data))
  } catch (e: any) {
    yield put(generateInvoiceActions.failure(e))
  }
}
function* handleCloseMonth() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const monthYear: string = yield select(getSelectedMonthYear)
    yield* call(() => closeMonthService(monthYear, options))
    yield put(
      closeMonthActions.success(
        `Month ${getMonthYearStringFromSelectedMonth(
          monthYear
        )} has been closed successfully`
      )
    )
  } catch (e: any) {
    yield put(closeMonthActions.failure(e))
  }
}
function* handleGetInvoices() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const selectedFeePlan: IFeePlan = yield select(getSelectedFeePlan)
    const accrualId: IInvoicesRequest[] = []
    if (selectedFeePlan?.Accruals) {
      selectedFeePlan?.Accruals?.map((x) => {
        x.FeeSchedules?.forEach((y) => {
          accrualId.push({ accrualid: y.AccrualId || 0 })
        })
      })
    }
    const data = yield* call(() => fetchInvoicesService(accrualId, options))
    yield put(getInvoicesActions.success(data || []))
  } catch (e: any) {
    yield put(getInvoicesActions.failure(e))
  }
}
function* handleDownloadD365Excel(
  action: ReturnType<typeof downloadD365ExcelActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const monthYear: string = yield select(getSelectedMonthYear)
    const legalEntity = action.payload
    yield* call(() => downloadD365ExcelService(monthYear, legalEntity, options))

    yield put(downloadD365ExcelActions.success())
  } catch (e: any) {
    yield put(downloadD365ExcelActions.failure(e))
  }
}

function* handleDeleteInvoice(
  action: ReturnType<typeof getInvoicesActions.deleteinvoice>
) {
  try {
    const invoiceid: string = action.payload as string
    if (invoiceid) {
      const options = yield* call(getRockefellerApiOptions)
      yield call(() => deleteFeeInvoiceService(invoiceid, options))
      const invoicelist: IFeeInvoices[] = yield select(getInvoicesState)
      const newlist = invoicelist.filter((invoice) => {
        return invoice.invoiceid?.toString() !== invoiceid
      })
      yield put(getInvoicesActions.success(newlist))
    } else {
      throw new Error('Invoice Id is not present')
    }
  } catch (e: any) {
    yield put(getInvoicesActions.failure(e))
  }
}

function* handleSendForCollection(
  action: ReturnType<typeof sendForCollectionActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    yield* call(() => sendForCollectionService(action.payload, options))

    yield put(sendForCollectionActions.success())
  } catch (e: any) {
    yield put(sendForCollectionActions.failure(e))
  }
}

function* handleUpdateAnnualAmount(
  action: ReturnType<typeof updateAmountActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    yield* call(() => updateAnnualAmountService(action.payload, options))
    const existingFeeSchedules = yield* select(getFeeListData)
    const shallowCopyofFees = [...existingFeeSchedules]
    const existingFee = existingFeeSchedules.filter(
      (x) => x.id === action.payload.id
    )?.[0]
    const index = existingFeeSchedules.findIndex(
      (x) => x.id === action.payload.id
    )

    if (index > -1) {
      shallowCopyofFees.splice(index, 1, {
        ...existingFee,
        AnnualAmount: action.payload.amount
      })
    }

    yield put(updateAmountActions.success(shallowCopyofFees))
  } catch (e: any) {
    yield put(updateAmountActions.failure(e))
  }
}

function* handleFetchInvoiceDetailsItems(
  action: ReturnType<typeof fetchInvoiceDetailItemsActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const data = yield* call(() =>
      fetchInvoiceDetailsItemsService(action.payload, options)
    )
    yield put(fetchInvoiceDetailItemsActions.success(data))
  } catch (e: any) {
    yield put(fetchInvoiceDetailItemsActions.failure(e))
  }
}

function* handleFetchFeeCategoriesType() {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const data = yield* call(() => fetchFeeCategoryTypesService(options))
    yield put(fetchFeeCategoriesActions.success(data))
  } catch (e: any) {
    yield put(fetchFeeCategoriesActions.failure(e))
  }
}

function* handleFetchFeeFrequenciesType() {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const data = yield* call(() => fetchFeeFrequenciesService(options))
    yield put(fetchFeeFrequencyTypeActions.success(data))
  } catch (e: any) {
    yield put(fetchFeeFrequencyTypeActions.failure(e))
  }
}

function* handleFetchAgencyFeeType(
  action: ReturnType<typeof fetchAgencyFeeTypeActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const data = yield* call(() =>
      fetchAgencyFeeService(options, action?.payload?.trustType)
    )
    yield put(fetchAgencyFeeTypeActions.success(data))
  } catch (e: any) {
    yield put(fetchAgencyFeeTypeActions.failure(e))
  }
}

function* handleFetchGLAccount(
  action: ReturnType<typeof fetchGLAccountActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const data = yield* call(() =>
      fetchGLAccountService(options, action.payload)
    )
    if (data.length > 0) {
      data[0].index = action.payload?.index
      data[0].isNested = action.payload?.isNested
      data[0].nestedIndex = action.payload?.nestedIndex
    } else {
      data.push({
        glaccount: '',
        index: action.payload?.index,
        isNested: action.payload?.isNested,
        nestedIndex: action.payload?.nestedIndex,
        feeServiceTypeCode: '',
        trusttype: ''
      })
    }
    yield put(fetchGLAccountActions.success(data))
  } catch (e: any) {
    yield put(fetchGLAccountActions.failure(e))
  }
}

function* handleFetchDepositAccount() {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const data = yield* call(() => fetchDepositAccountService(options))
    yield put(fetchDepositAccountActions.success(data))
  } catch (e: any) {
    yield put(fetchDepositAccountActions.failure(e))
  }
}

function* handleFetchFeeBusinessSegmentType() {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const data = yield* call(() => fetchFeeBusinessSegmentsService(options))
    yield put(fetchFeeBusinessSegmentTypeActions.success(data))
  } catch (e: any) {
    yield put(fetchFeeBusinessSegmentTypeActions.failure(e))
  }
}

function* handleFetchFeeCollectionType() {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const data = yield* call(() => fetchFeeCollectionTypesService(options))
    yield put(fetchFeeCollectionTypeActions.success(data))
  } catch (e: any) {
    yield put(fetchFeeCollectionTypeActions.failure(e))
  }
}

function* handleFetchFeeRepCodes() {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const data = yield* call(() => fetchFeeRepCodesService(options))
    yield put(fetchFeeAdvisorActions.success(data))
  } catch (e: any) {
    yield put(fetchFeeAdvisorActions.failure(e))
  }
}

function* handleFetchFeeType(
  action: ReturnType<typeof fetchFeeServiceTypeActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const data = yield* call(() =>
      fetchFeeServiceTypesService(action.payload, options)
    )
    yield put(fetchFeeServiceTypeActions.success(data))
  } catch (e: any) {
    yield put(fetchFeeServiceTypeActions.failure(e))
  }
}

function* handleFetchInvoiceFeeType(
  action: ReturnType<typeof fetchInvoiceFeeTypeActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const data = yield* call(() =>
      fetchInvoiceFeeTypesService(action.payload, options)
    )
    yield put(fetchInvoiceFeeTypeActions.success(data))
  } catch (e: any) {
    yield put(fetchInvoiceFeeTypeActions.failure(e))
  }
}

function* handleCreateNewFee(
  action: ReturnType<typeof createNewFeeActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const payload = action.payload
    const request: NewFeeRequest = {
      clientAccountant: payload.clientAccountant,
      clientId: payload.clientId,
      clientSegment: payload?.clientSegment,
      collectionMethod: payload?.collectionMethod,
      comments: payload?.comments,
      feeCategory: payload?.feeCategory,
      feeScheduleStartDate: payload.feeScheduleStartDate
        ? format(payload.feeScheduleStartDate, 'MM/dd/yyyy')
        : '',
      feeScheduleStatus: 'ACTIV',
      feeServiceType: payload?.feeServiceType,
      frequency: payload?.frequency,
      invoiceFeeType: payload?.invoiceFeeType,
      invoiceRequired: payload?.invoiceRequired,
      legalEntity: payload?.legalEntity,
      paymentAccountNo: payload?.paymentAccountNo,
      repEmpCode: payload?.repEmpCode,
      tiers: [
        {
          flatFee: parseFloat(payload?.amount || '0')
        }
      ]
    }

    yield* call(() => createFeeService(request, options))
    yield put(createNewFeeActions.success())
  } catch (e: any) {
    yield put(createNewFeeActions.failure(e))
  }
}

function* handleUpdateNewFee(
  action: ReturnType<typeof updateNewFeeActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const payload = action.payload
    const request: UpdateFeeRequest = {
      paymentAccountNo: payload?.paymentAccountNo,
      tiers: [
        {
          flatFee: parseFloat(payload?.amount || '0')
        }
      ],
      LegalEntity: payload?.legalEntity,
      repEmpCode: payload.repEmpCode,
      FeeServicetype: payload.feeServiceType,
      D365Clientid: payload.D365Clientid,
      ClientSegment: payload.clientSegment,
      feeScheduleId: payload.feeScheduleId
    }
    yield* call(() => updateFeeScheduleService(request, options))
    yield put(updateNewFeeActions.success())
  } catch (e: any) {
    yield put(updateNewFeeActions.failure(e))
  }
}

function* handleFeeGLString(
  action: ReturnType<typeof feeGLStringActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const data = yield* call(() => fetchFeeGLString(action.payload, options))
    yield put(feeGLStringActions.success(data))
  } catch (e: any) {
    yield put(feeGLStringActions.failure(e))
  }
}

function* handleCreateNewFeeSchedule(
  action: ReturnType<typeof insertUpdateFeeScheduleActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const payload: IFeeScheduleForm = action.payload

    const response = yield* call(() =>
      createFeeScheduleService(payload, options)
    )

    yield put(insertUpdateFeeScheduleActions.success(response?.feedata))
  } catch (e: any) {
    yield put(insertUpdateFeeScheduleActions.failure(e))
  }
}

function* handleFetchFeeScheduleData() {
  try {
    const feeSchedule: IFeeListDetail = yield select(getSelectedFeeSchedule)
    if (feeSchedule) {
      const options = yield* call(getRockefellerApiOptions)
      const data: IFeeScheduleForm = yield call(() =>
        fetchFeeScheduleService(feeSchedule, options)
      )
      yield put(feeScheduleDataActions.success(data))
    } else {
      throw new Error('Fee schedule Id is present')
    }
  } catch (e: any) {
    yield put(feeTypeActions.failure(e))
  }
}

function* handleNavigateBackToFeeSchedule() {
  yield put(feeScheduleDataActions.reset())
  yield put(push('/fees/feegrid'))
}

function* handleInsertUpdateOverride(
  action: ReturnType<typeof insertUpdateFeeOverrideActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const requestpayload = formatInsertUpdateServiceRequest(action.payload)
    const apiResp = yield* call(() =>
      insertUpdateFeeOverrideService(requestpayload, options)
    )

    if (apiResp?.errorcode === '0') {
      yield put(insertUpdateFeeOverrideActions.success())
      yield put(fetchExistingOverrideByIdActions.success(apiResp?.overridedata))
    } else {
      yield put(
        insertUpdateFeeOverrideActions.failure(
          new Error(
            apiResp?.errormessage ||
              'Unable to insert/update fee overrides, please try after sometime.'
          )
        )
      )
    }
  } catch (e: any) {
    yield put(
      insertUpdateFeeOverrideActions.failure(
        new Error(
          'Unable to insert/update fee overrides, please try after sometime.'
        )
      )
    )
  }
}

function* handleFetchAssetSecurityData(
  action: ReturnType<typeof fetchAssetSecurityDataActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const response = yield* call(() =>
      fetchAssetClassSecurityService(action.payload, options)
    )
    yield put(fetchAssetSecurityDataActions.success(response))
  } catch (e: any) {
    yield put(fetchAssetSecurityDataActions.failure(e))
  }
}

function* handleFetchAssetSecurityDataPerRow(
  action: ReturnType<typeof fetchAssetSecurityDataPerRowActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token,
      cancelToken: source.token
    }

    const response = yield* call(
      fetchAssetClassSecurityService,
      action.payload.searchString,
      options
    )

    const existingData = yield* select(getAssetSecurityDataByRowState)
    const shallowClonedExistingData = [...(existingData || [])]
    const itemIdx = shallowClonedExistingData.findIndex(
      (x) => x.rowid === action.payload.rowid
    )
    const newRowData: IAssetLookupByRowResponse = {
      rowid: action.payload.rowid,
      data: response,
      error: undefined,
      inProgress: false
    }

    if (itemIdx > -1) {
      shallowClonedExistingData.splice(itemIdx, 1, newRowData)
    } else {
      shallowClonedExistingData.push(newRowData)
    }

    yield put(
      fetchAssetSecurityDataPerRowActions.success(shallowClonedExistingData)
    )
  } catch (e: any) {
    const existingData = yield* select(getAssetSecurityDataByRowState)
    const shallowClonedExistingData = [...(existingData || [])]
    const itemIdx = shallowClonedExistingData.findIndex(
      (x) => x.rowid === action.payload.rowid
    )
    const newRowData: IAssetLookupByRowResponse = {
      rowid: action.payload.rowid,
      data: undefined,
      error: e,
      inProgress: false
    }

    if (itemIdx > -1) {
      shallowClonedExistingData.splice(itemIdx, 1, newRowData)
    } else {
      shallowClonedExistingData.push(newRowData)
    }
    yield put(
      fetchAssetSecurityDataPerRowActions.failure(shallowClonedExistingData)
    )
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

function* handleFetchSecurities(
  action: ReturnType<typeof fetchSecuritiesActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token,
      cancelToken: source.token
    }
    const response = yield* call(() =>
      fetchAssetClassSecurityService(action.payload, options)
    )
    yield put(fetchSecuritiesActions.success(response))
  } catch (e: any) {
    yield put(fetchSecuritiesActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

function* handleFetchExistingOverrideById(
  action: ReturnType<typeof fetchExistingOverrideByIdActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token,
      cancelToken: source.token
    }
    const response = yield* call(() =>
      fetchExistingOverridebyIdService(action.payload, options)
    )
    yield put(fetchExistingOverrideByIdActions.success(response))
  } catch (e: any) {
    yield put(fetchExistingOverrideByIdActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}
function* handleFetchFeeAssignment(
  action: ReturnType<typeof fetchFeeAssignmentDataActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token,
      cancelToken: source.token
    }
    const response = yield* call(() =>
      fetchFeeAssignmentService(action.payload, options)
    )
    yield put(fetchFeeAssignmentDataActions.success(response))
  } catch (e: any) {
    yield put(fetchFeeAssignmentDataActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

function* handleFetchTrustCategoryAssignment(
  action: ReturnType<typeof fetchTrustAssignmentDataActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token,
      cancelToken: source.token
    }
    const response = yield* call(() =>
      fetchTrustCategoryAssignmentService(action.payload, options)
    )
    yield put(fetchTrustAssignmentDataActions.success(response))
  } catch (e: any) {
    yield put(fetchTrustAssignmentDataActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

function* handleIsTrustCategoryAssignmentExists(
  action: ReturnType<typeof fetchTrustAssignmentDataActions.trustExist>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token,
      cancelToken: source.token
    }
    const response = yield* call(() =>
      fetchTrustCategoryAssignmentService(action.payload, options)
    )
    yield put(fetchTrustAssignmentDataActions.isTrustExistSuccess(response))
  } catch (e: any) {
    yield put(fetchTrustAssignmentDataActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

function* handleTrustCategoryAction(
  action: ReturnType<typeof handleTrustActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token,
      cancelToken: source.token
    }
    const response = yield* call(() =>
      handleTrustCategoryActionService(action.payload, options)
    )
    if (response?.status === 'success') {
      if (action.payload.actionCode === 'D') {
        response.message = 'Trust category deleted successfully'
      } else if (action.payload.actionCode === 'U') {
        response.message = 'Trust category updated successfully'
      } else if (action.payload.actionCode === 'I') {
        response.message = 'Trust category assigned successfully'
      }
      yield put(handleTrustActions.success(response))
    } else {
      const e: any = {}
      yield put(handleTrustActions.failure(e))
    }
  } catch (e: any) {
    yield put(handleTrustActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

function* navigateToAssignmentForm(
  action: ReturnType<
    typeof fetchFeeAssignmentDataActions.navigateToAssignmentForm
  >
) {
  yield put(
    push(
      `/fees/tiredaumfees?id=${action.payload?.id}&status=${action.payload?.status}&partyid=${action.payload?.partyid}&mode=${action.payload?.mode}`
    )
  )
}

function* navigateToTrustAssignmentPage(
  action: ReturnType<
    typeof fetchTrustAssignmentDataActions.navigateToTrustAssignmentPage
  >
) {
  yield put(
    push(
      `/fees/assigntrusttype?id=${action.payload?.id}&status=${action.payload?.status}&partyid=${action.payload?.partyid}&mode=${action.payload?.mode}`
    )
  )
}

function* handleFetchFeeOverridesList(
  action: ReturnType<typeof fetchFeeOverridesDataActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token,
      cancelToken: source.token
    }
    const response = yield* call(() =>
      fetchFeeOverridesListService(
        action.payload as IFeeScheduleFilter,
        options
      )
    )
    yield put(fetchFeeOverridesDataActions.success(response))
  } catch (e: any) {
    yield put(fetchFeeOverridesDataActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

function* navigateToFeeOverrideForm(
  action: ReturnType<typeof fetchFeeOverridesDataActions.navigateToOverrideForm>
) {
  const payload = action.payload
  let url = `/fees/feesoverride`
  if (payload?.overrideSummaryID) {
    url += `?id=${payload.overrideSummaryID}&draftid=${
      payload.draftoverrideSummaryID ? payload.draftoverrideSummaryID : 0
    }&status=${payload.isdraft ? 'D' : 'C'}`
  }
  yield put(push(url))
}

function* navigateToFeeOverrideFormView(
  action: ReturnType<
    typeof fetchFeeOverridesDataActions.navigateToOverrideFormView
  >
) {
  const { pathname } = yield* call(getLocation)
  const payload = action.payload
  let url = `/fees/feesoverride/view`
  if (payload?.overrideSummaryID) {
    url += `?id=${payload.overrideSummaryID}&draftid=${
      payload.draftoverrideSummaryID ? payload.draftoverrideSummaryID : 0
    }&status=${payload.isdraft ? 'D' : 'C'}`
  }
  if (pathname !== '/fees/feeassignment') {
    yield put(push(url))
  }
}

function* navigateToGroupForm(
  action: ReturnType<typeof fetchFeeGroupDataActions.navigateToGroupForm>
) {
  const { id, status, draftId } = action.payload
  yield put(
    push(`/fees/buildgroups?id=${id}&status=${status}&draftId=${draftId}`)
  )
}

function* navigateToGroupFormView(
  action: ReturnType<typeof fetchFeeGroupDataActions.navigateToGroupFormView>
) {
  const { id, status, draftId } = action.payload
  yield put(
    push(`/fees/buildgroups/view?id=${id}&status=${status}&draftId=${draftId}`)
  )
}

function* deleteFeeOverride(
  action: ReturnType<typeof deleteFeeOverrideActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const requestpayload = formatDeleteFeeRequest(action.payload.item)
    yield* call(() => insertUpdateFeeOverrideService(requestpayload, options))
    yield put(deleteFeeOverrideActions.success())
    yield put(
      fetchFeeOverridesDataActions.request({
        status: action.payload.selectedStatus
      })
    )
  } catch (e: any) {
    yield put(deleteFeeOverrideActions.failure(e))
  }
}

function* deleteFeeAssignment(
  action: ReturnType<typeof deleteFeeAssignmentActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    yield* call(() => deleteFeeAssignmentService(action.payload, options))
    yield put(deleteFeeAssignmentActions.success())
    if (action.payload?.isOnlyDelete !== true) {
      yield put(
        fetchFeeAssignmentDataActions.request(action.payload.selectedStatus)
      )
    }
  } catch (e: any) {
    yield put(deleteFeeAssignmentActions.failure(e))
  }
}
function* deleteFeeGroup(
  action: ReturnType<typeof deleteFeeGroupActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const requestpayload = formatDeleteFeeGroupRequest(action.payload.item)
    yield* call(() => insertUpdateFeeGroupService(requestpayload, options))
    yield put(deleteFeeGroupActions.success())
    yield put(fetchFeeGroupDataActions.request(action.payload.selectedStatus))
  } catch (e: any) {
    yield put(deleteFeeGroupActions.failure(e))
  }
}
// eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
function* handleFetchFeeGroupList(
  action: ReturnType<typeof fetchFeeGroupDataActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token,
      cancelToken: source.token
    }
    const response = yield* call(() =>
      fetchFeeGroupListService(action.payload, options)
    )
    const formatedRes = response?.map((a) => {
      a.adminAnnualMinFee = Number(a.adminAnnualMinFee?.toFixed(2) || 0)
      a.imAnnualMinFee = Number(a.imAnnualMinFee?.toFixed(2) || 0)
      return a
    })
    yield put(fetchFeeGroupDataActions.success(formatedRes))
  } catch (e: any) {
    yield put(fetchFeeGroupDataActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

function* handleInsertUpdateFeeBuildGroup(
  action: ReturnType<typeof insertUpdateFeeBuildGroupActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const response = yield* call(() =>
      insertUpdateFeeBuildGroupService(action.payload, options)
    )
    if (response?.errorcode === '0') {
      yield put(insertUpdateFeeBuildGroupActions.success(response?.groupdata))
    } else {
      yield put(
        insertUpdateFeeBuildGroupActions.failure(
          new Error(
            response?.errormessage ||
              'Unable to insert/update fee build group. Please try after sometime.'
          )
        )
      )
    }
  } catch (e: any) {
    yield put(insertUpdateFeeBuildGroupActions.failure(e))
  }
}

function* handleFetchExistingFeeBuildGroup(
  action: ReturnType<typeof fetchExistingBuildGroupActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const data = yield* call(() =>
      fetchExistingFeeBuildGroupService(
        {
          id: action.payload.id,
          status: action.payload.status,
          draftId: action.payload.draftId
        },
        options
      )
    )
    yield put(fetchExistingBuildGroupActions.success(data))
  } catch (e: any) {
    yield put(fetchExistingBuildGroupActions.failure(e))
  }
}

function* handleFetchGroupByAccountId(
  action: ReturnType<typeof fetchGroupByAccountIdActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const response = yield* call(() =>
      fetchGroupByAccountIdService(action.payload.accountNo, options)
    )

    const existingGroupData = yield* select(getFetchGroupByAccountIdState)
    const shallowData = [...(existingGroupData || [])]
    const matchedIdx = shallowData.findIndex(
      (x) => x.rowId === action.payload.rowId
    )
    const newRow: IGroupRow = {
      data: response,
      error: undefined,
      inProgress: false,
      rowId: action.payload.rowId
    }
    if (matchedIdx > -1) {
      shallowData.splice(matchedIdx, 1, newRow)
    } else {
      shallowData.push(newRow)
    }

    yield put(fetchGroupByAccountIdActions.success(shallowData))
  } catch (e: any) {
    const existingGroupData = yield* select(getFetchGroupByAccountIdState)
    const shallowData = [...(existingGroupData || [])]
    const matchedIdx = shallowData.findIndex(
      (x) => x.rowId === action.payload.rowId
    )
    const newRow: IGroupRow = {
      data: undefined,
      error: e,
      inProgress: false,
      rowId: action.payload.rowId
    }
    if (matchedIdx > -1) {
      shallowData.splice(matchedIdx, 1, newRow)
    } else {
      shallowData.push(newRow)
    }
    yield put(fetchGroupByAccountIdActions.failure(e))
  }
}

function* handleInsertUpdateTiredAumFees(
  action: ReturnType<typeof insertUpdateTiredAumFeesActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const apiResp = yield* call(() =>
      insertUpdateTiredAumFeesService(action.payload, options)
    )

    if (apiResp[0].errorcode === '0') {
      yield put(insertUpdateTiredAumFeesActions.success(apiResp[0]?.feedetails))
    } else {
      yield put(
        insertUpdateTiredAumFeesActions.failure(
          new Error(
            apiResp[0]?.errormessage ||
              'Unable to insert/update Tiered aum fees, please try after sometime.'
          )
        )
      )
    }
  } catch (e: any) {
    yield put(
      insertUpdateTiredAumFeesActions.failure(
        new Error(
          'Unable to insert/update Tiered aum fees, please try after sometime.'
        )
      )
    )
  }
}

function* handleFetchExistingTiredAumFees(
  action: ReturnType<typeof fetchExistingTiredAumFeesActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const data = yield* call(() =>
      fetchExistingTiredAumFeesService(
        {
          id: action.payload.id,
          status: action.payload.status,
          partyid: action.payload.partyid
        },
        options
      )
    )
    //const res: IAccountRootModel = JSON.parse(JSON.stringify(dt))
    yield put(fetchExistingTiredAumFeesActions.success(data))
  } catch (e: any) {
    yield put(fetchExistingTiredAumFeesActions.failure(e))
  }
}

function* handleAccount(
  action: ReturnType<typeof fetchAccountActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const data = yield* call(() =>
      fetchAccountService(
        {
          legalentityid: action.payload.legalentityid
        },
        options
      )
    )
    yield put(fetchAccountActions.success(data))
  } catch (e: any) {
    yield put(fetchAccountActions.failure(e))
  }
}

function* handleFetchFlatFeeSchedule(
  action: ReturnType<typeof getFlatFeeScheduleAction.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchFlatFeeScheduleService(options, action.payload.partyUniqueId)
    )
    yield put(getFlatFeeScheduleAction.success(data))
  } catch (e: any) {
    yield put(
      getFlatFeeScheduleAction.failure({
        error: e
      })
    )
  }
}

function* handleUpdateFlatFeeSchedule(
  action: ReturnType<typeof updateFlatFeeScheduleAction.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)

    const data = yield* call(() =>
      updateFlatFeeScheduleService(action.payload, options)
    )

    data.map((item, index) => {
      item.feeScheduleId = action.payload?.[index]?.FeeScheduleId
    })

    yield put(updateFlatFeeScheduleAction.success(data))
  } catch (e: any) {
    yield put(
      updateFlatFeeScheduleAction.failure({
        error: e
      })
    )
  }
}

function* getAccountsForSchedule(
  action: ReturnType<typeof getAccountsForScheduleActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const data = yield* call(() =>
      fetchAccountsForScheduleService(
        {
          feestrutureid: action.payload.feestrutureid
        },
        options
      )
    )
    yield put(getAccountsForScheduleActions.success(data))
  } catch (e: any) {
    yield put(
      getAccountsForScheduleActions.failure({
        error: e
      })
    )
  }
}

function* handleGwesReportToExcel(
  action: ReturnType<typeof exportToExcelGwesReportActions.request>
) {
  try {
    const { monthYear, columns } = action.payload

    const parsedDate = parse(monthYear, 'MM-yyyy', new Date())
    const formattedDate = format(parsedDate, 'LLLL-yyyy')
    const filename = `GWES_Reports_${formattedDate}.csv`
    const wsName = 'GWES Report'
    const filteredcolumns = columns?.filter(
      (x) => x.key !== 'action' && x.key !== 'action1'
    )
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const apiData = yield* call(() => getGwesReportService(monthYear, options))

    const data = apiData?.data?.map((gwesReport) => {
      const exportExcelGwesReport = {
        ...gwesReport,
        dateToPost: new Date(gwesReport?.dateToPost ?? '')
          .toISOString()
          .split('T')[0]
      }
      return filteredcolumns?.map((column): unknown =>
        column.fieldName ? get(exportExcelGwesReport, column.fieldName) : ''
      )
    })

    // data?.unshift(
    //   columns?.filter((x) => x.key !== 'details')?.map((x) => x.name)
    // )

    yield call(() =>
      exportDataToExcel(
        {
          sheets: [{ name: wsName, data: data || [] }]
        },
        filename
      )
    )
    yield put(exportToExcelGwesReportActions.success())
  } catch (e: any) {
    yield put(exportToExcelGwesReportActions.failure(e))
  }
}

function* handleExportPlatformNetflowsToExcel(
  action: ReturnType<typeof exportPlatformNetFlowsActions.request>
) {
  try {
    const { netFlows, columns } = action.payload
    const filename = 'NetFlows.xlsx'
    const wsName = 'NetFlows'
    const data = netFlows?.map((plan) => {
      const excelPlan = {
        ...plan
      }
      return columns?.map((column): unknown =>
        column.fieldName ? get(excelPlan, column.fieldName) : ''
      )
    })
    data?.unshift(
      columns?.filter((x) => x.key !== 'details')?.map((x) => x.name)
    )
    yield call(() =>
      exportDataToExcel(
        {
          sheets: [{ name: wsName, data: data || [] }]
        },
        filename
      )
    )
    yield put(exportPlatformNetFlowsActions.success())
  } catch (e: any) {
    yield put(exportPlatformNetFlowsActions.failure(e))
  }
}

export const sagas = [
  () =>
    takeLatest(getFlatFeeScheduleAction.request, handleFetchFlatFeeSchedule),
  () =>
    takeEvery(updateFlatFeeScheduleAction.request, handleUpdateFlatFeeSchedule),
  () => takeLatest(feeListActions.request, handleFetchFeeSchedulesInfo),
  () =>
    takeLatest(feeScheduleListActions.request, handleFetchFeeGridSchedulesInfo),
  () =>
    takeEvery(feeScheduleListActions.viewfeeschedule, navigateToFeeGridView),
  () => takeEvery(feeListActions.viewfee, navigateToFeeView),
  () => takeEvery(feeListActions.editfee, navigateToFeeEdit),
  () =>
    takeEvery(feeScheduleListActions.editfeeschedule, navigateToFeeGridEdit),
  () => takeEvery(feeListActions.deletefee, handleDeleteFee),
  () =>
    takeEvery(
      feeScheduleListActions.deletefeeschedule,
      handleDeleteFeeSchedule
    ),
  () =>
    takeLatest(
      feeScheduleListActions.updatefeeschedule,
      handleActiveInActiveFeeSchedule
    ),
  () => takeEvery(feeTypeActions.request, handleFetchFeeTypes),
  () => takeEvery(feeDataActions.request, handleFetchFeeData),
  () => takeEvery(feePastChargesActions.request, handleFetchPastCharges),
  () => takeEvery(feeFutureChargesActions.request, handleFetchFutureCharges),
  () => takeEvery(feeDataActions.navigateBackToFee, handleNaviagteToFee),
  () => takeEvery(feeFormActions.addfee, handleAddNewFee),
  () => takeLatest(chargeFeeActions.request, handleChargeFee),
  () => takeLatest(exportScheduledFeeActions.request, handleExportScheduledFee),
  () => takeLatest(feeCategoryTypeActions.request, handleFetchFeeCategories),
  () =>
    takeLatest(
      feeNavigationActions.navigateToAccruedAmount,
      handleNavigateToAccruedAmount
    ),
  () =>
    takeLatest(
      feeNavigationActions.navigateToFeeDashboard,
      handleNavigateToFeeDashboard
    ),
  () => takeLatest(feeDashboardActions.request, handleFetchFeeDashboard),
  () =>
    takeLatest(
      feeBillingDashboardActions.request,
      handleFetchFeeBillingDashboard
    ),
  () =>
    takeLatest(
      platformFeeScheduleActions.request,
      handleFetchPlatformFeeSchedule
    ),

  () =>
    takeLatest(
      updatePlatformFeeScheduleActions.request,
      handleUpdatePlatformFeeSchedule
    ),
  () =>
    takeLatest(
      exportplatformFeeScheduleToExcelActions.request,
      handleExportPlatformFeeScheduleToExcel
    ),
  () =>
    takeLatest(platformFeeDetailActions.request, handleFetchPlatformFeeDetail),
  () =>
    takeLatest(
      platformFeeCalcPeriodActions.request,
      handleFetchPlatformCalcPeriodDetail
    ),
  () => takeLatest(runBillingActions.request, handleRunBilling),
  () => takeLatest(fetchAccrualsActions.request, handleFetchAccruals),
  () =>
    takeLatest(exportFeePlansToExcelActions.request, handleExportPlansToExcel),
  () =>
    takeLatest(
      exportFeeBillingToExcelActions.request,
      handleExportFeeBillingToExcel
    ),
  () => takeLatest(pilotFetaureActions.request, handlePilotFeatures),
  () => takeLatest(UpdateAccrualsActions.request, handleUpdateAccruals),
  () => takeLatest(viewInvoiceActions.request, handleViewInvoice),
  () => takeLatest(generateInvoiceActions.request, handleGenerateInvoice),
  () => takeLatest(closeMonthActions.request, handleCloseMonth),
  () => takeLatest(getInvoicesActions.request, handleGetInvoices),
  () => takeLatest(downloadD365ExcelActions.request, handleDownloadD365Excel),
  () => takeLatest(getInvoicesActions.deleteinvoice, handleDeleteInvoice),
  () => takeLatest(sendForCollectionActions.request, handleSendForCollection),
  () => takeLatest(updateAmountActions.request, handleUpdateAnnualAmount),
  () =>
    takeLatest(
      fetchInvoiceDetailItemsActions.request,
      handleFetchInvoiceDetailsItems
    ),

  () =>
    takeLatest(fetchFeeCategoriesActions.request, handleFetchFeeCategoriesType),
  () =>
    takeLatest(
      fetchFeeFrequencyTypeActions.request,
      handleFetchFeeFrequenciesType
    ),
  () => takeLatest(fetchAgencyFeeTypeActions.request, handleFetchAgencyFeeType),
  () => takeLatest(fetchGLAccountActions.request, handleFetchGLAccount),
  () =>
    takeLatest(fetchDepositAccountActions.request, handleFetchDepositAccount),
  () =>
    takeLatest(
      fetchFeeBusinessSegmentTypeActions.request,
      handleFetchFeeBusinessSegmentType
    ),
  () =>
    takeLatest(
      fetchFeeCollectionTypeActions.request,
      handleFetchFeeCollectionType
    ),
  () => takeLatest(fetchFeeAdvisorActions.request, handleFetchFeeRepCodes),
  () => takeLatest(fetchFeeServiceTypeActions.request, handleFetchFeeType),
  () =>
    takeLatest(fetchInvoiceFeeTypeActions.request, handleFetchInvoiceFeeType),
  () => takeLatest(createNewFeeActions.request, handleCreateNewFee),
  () => takeLatest(updateNewFeeActions.request, handleUpdateNewFee),
  () => takeLatest(feeGLStringActions.request, handleFeeGLString),
  () =>
    takeLatest(
      insertUpdateFeeScheduleActions.request,
      handleCreateNewFeeSchedule
    ),
  () => takeEvery(feeScheduleDataActions.request, handleFetchFeeScheduleData),
  () =>
    takeEvery(
      feeScheduleDataActions.navigateBackToFeeSchedule,
      handleNavigateBackToFeeSchedule
    ),
  () =>
    takeLatest(
      insertUpdateFeeOverrideActions.request,
      handleInsertUpdateOverride
    ),
  () =>
    takeLatest(
      fetchAssetSecurityDataActions.request,
      handleFetchAssetSecurityData
    ),
  () =>
    takeLatest(
      fetchAssetSecurityDataPerRowActions.request,
      handleFetchAssetSecurityDataPerRow
    ),
  () => takeLatest(fetchSecuritiesActions.request, handleFetchSecurities),
  () =>
    takeLatest(
      fetchExistingOverrideByIdActions.request,
      handleFetchExistingOverrideById
    ),
  () =>
    takeLatest(
      fetchFeeOverridesDataActions.request,
      handleFetchFeeOverridesList
    ),
  () => takeLatest(fetchFeeGroupDataActions.request, handleFetchFeeGroupList),
  () =>
    takeLatest(
      fetchFeeAssignmentDataActions.navigateToAssignmentForm,
      navigateToAssignmentForm
    ),
  () =>
    takeLatest(
      fetchTrustAssignmentDataActions.navigateToTrustAssignmentPage,
      navigateToTrustAssignmentPage
    ),
  () =>
    takeLatest(
      fetchFeeOverridesDataActions.navigateToOverrideForm,
      navigateToFeeOverrideForm
    ),
  () =>
    takeLatest(
      fetchFeeOverridesDataActions.navigateToOverrideFormView,
      navigateToFeeOverrideFormView
    ),
  () => takeLatest(deleteFeeAssignmentActions.request, deleteFeeAssignment),
  () =>
    takeEvery(
      fetchGroupByAccountIdActions.request,
      handleFetchGroupByAccountId
    ),
  () =>
    takeLatest(
      insertUpdateTiredAumFeesActions.request,
      handleInsertUpdateTiredAumFees
    ),
  () =>
    takeLatest(
      fetchTrustAssignmentDataActions.request,
      handleFetchTrustCategoryAssignment
    ),
  () =>
    takeLatest(
      fetchTrustAssignmentDataActions.trustExist,
      handleIsTrustCategoryAssignmentExists
    ),
  () => takeLatest(handleTrustActions.request, handleTrustCategoryAction),
  () =>
    takeLatest(fetchFeeAssignmentDataActions.request, handleFetchFeeAssignment),
  () =>
    takeLatest(
      insertUpdateFeeBuildGroupActions.request,
      handleInsertUpdateFeeBuildGroup
    ),
  () =>
    takeLatest(
      fetchFeeGroupDataActions.navigateToGroupForm,
      navigateToGroupForm
    ),
  () =>
    takeLatest(
      fetchFeeGroupDataActions.navigateToGroupFormView,
      navigateToGroupFormView
    ),
  () => takeLatest(deleteFeeOverrideActions.request, deleteFeeOverride),
  () =>
    takeLatest(
      fetchExistingBuildGroupActions.request,
      handleFetchExistingFeeBuildGroup
    ),
  () => takeLatest(deleteFeeGroupActions.request, deleteFeeGroup),
  () =>
    takeLatest(
      fetchExistingTiredAumFeesActions.request,
      handleFetchExistingTiredAumFees
    ),
  () => takeLatest(fetchAccountActions.request, handleAccount),
  () =>
    takeLatest(getAccountsForScheduleActions.request, getAccountsForSchedule),
  () =>
    takeLatest(exportToExcelGwesReportActions.request, handleGwesReportToExcel),
  () =>
    takeLatest(
      exportPlatformNetFlowsActions.request,
      handleExportPlatformNetflowsToExcel
    )
]
