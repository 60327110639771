import { skipToken } from '@reduxjs/toolkit/query'
import { DateOnlyCell } from 'features/Lists/core/components/DateCell'
import { USDCell } from 'features/Lists/core/components/USDCell'
import { LoadingComponent } from 'shared/components/Loading'
import { ConnectedMaskedText } from 'shared/components/MaskedText'
import {
  IFeeDetailRequest,
  useGetManagedAccountFeeDetailsQuery
} from '../../../api'

export const PendingClientFees: React.FC<Partial<IFeeDetailRequest>> = ({
  accountNumber,
  billDate
}) => {
  const { data, isFetching } = useGetManagedAccountFeeDetailsQuery(
    accountNumber && billDate ? { accountNumber, billDate } : skipToken
  )

  return (
    <div style={{ display: 'flex', gap: 5, padding: '20px' }}>
      {isFetching && <LoadingComponent />}
      {!isFetching && (
        <table
          css={{
            padding: '10px',
            '& th, & td': { padding: '1px 5px', textAlign: 'right' }
          }}
        >
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}>Account</th>
              <th>Managed Assets</th>
              <th>Client Fee</th>
              <th style={{ textAlign: 'left' }}>Bill Type</th>
              <th>Period Start</th>
              <th>Period End</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((detail, index) => (
              <tr key={index}>
                <td style={{ textAlign: 'left' }}>
                  <ConnectedMaskedText text={detail.account} />
                </td>
                <td>
                  <USDCell value={detail.billableValue} />
                </td>
                <td>
                  <USDCell value={detail.clientFee} />
                </td>
                <td style={{ textAlign: 'left' }}>{detail.billType}</td>
                <td>
                  <DateOnlyCell value={detail.billPeriodStart} />
                </td>
                <td>
                  <DateOnlyCell value={detail.billPeriodEnd} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}
